import initSlickWithArrowEvent from "../../components/slick-utils";

function initShopProductSectionProductSlick() {
    const slick = initSlickWithArrowEvent(
        $('.maier-section-product-visual-aside'),
        {
            vertical: true,
            dots: false,
            arrows: true,
            prevArrow: '<span class="slide-arrow prev">Prev</span>',
            nextArrow: '<span class="slide-arrow next">Next</span>',
            autoplay: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1
        }
    );

    $('.maier-section-product-visual-mobile').not('.slick-initialized').slick({
        vertical: false,
        dots: true,
        arrows: false,
        prevArrow: '<span class="slide-arrow prev">Prev</span>',
        nextArrow: '<span class="slide-arrow next">Next</span>',
        autoplay: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });
}

function initProductOptionParameterEvents() {
    document.querySelectorAll('.product-option-parameter select').forEach(function (select) {
        select.addEventListener('focus', function () {
            this.size = 5;
        });

        select.addEventListener('blur', function () {
            this.size = 1;
        });

        select.addEventListener('change', function () {
            this.size = 1;
            this.blur();
        });
    });
}

document.addEventListener('DOMContentLoaded', function () {
    initShopProductSectionProductSlick();
    initProductOptionParameterEvents();
});