export default class Modal {
    constructor({
        selector = null,
        closeButtonSelector = null,
        excludedAutoCloseSelectors = [],
        // Create
        create = false,
        className = null
    }) {
        this.selector = selector;
        this.closeButtonSelector = closeButtonSelector;

        if (true === create) {
            this.create(className);
        }

        this.element = document.querySelector(this.selector);
        this.closeButton = document.querySelector(this.closeButtonSelector);

        this.excludedAutoCloseSelectors = excludedAutoCloseSelectors;

        if (null !== this.element) {
            this.hide();
            this.init();
        }
    }
    create (className) {
        this.selector = '.maier-modal.' + className;
        this.closeButtonSelector = this.selector + ' button.close';

        if (null !== document.querySelector(this.selector)) {
            return;
        }

        let div = document.createElement('div');
        div.innerHTML = '<div class="'+ className +' maier-modal"><div class="modal-content"><button class="close">&times;</button><div class="content"></div></div></div>';

        document.querySelector('body').appendChild(div.firstChild);
    }
    init () {
        if (this.element.classList.contains('modal-initialized')) {
            return;
        }

        document.addEventListener('click', (e) => {
            if (
                this.isVisible() &&
                (
                    -1 === e.composedPath().indexOf(this.element) ||
                    -1 === e.composedPath().indexOf(this.element.querySelector('.modal-content'))
                )
            ) {
                for (let excludedAutoCloseSelector of this.excludedAutoCloseSelectors) {
                    if (-1 !== e.composedPath().indexOf(document.querySelector(excludedAutoCloseSelector))) {
                        return;
                    }
                }

                this.hide();
            }
        }, {passive: true})

        if (null !== this.closeButton) {
            this.closeButton.addEventListener('click', (e) => { this.hide(); }, {passive: true});
        }

        this.element.classList.add('modal-initialized');
    }
    isVisible() {
        return this.element.classList.contains('modal-open');
    }
    show() {
        [document.body, this.element].forEach((element) => {
            element.classList.add('modal-open');
        });
    }
    hide() {
        [document.body, this.element].forEach((element) => {
            element.classList.remove('modal-open');
        });
    }
}
