import initSlickWithArrowEvent from "../components/slick-utils";

function initMaierCarouselPresentationSlick() {
    const slick = initSlickWithArrowEvent(
        $('.maier-carousel-presentation-list'),
        {
            dots: false,
            arrows: true,
            prevArrow: '<span class="slide-arrow slide-arrow-white prev">Prev</span>',
            nextArrow: '<span class="slide-arrow slide-arrow-white next">Next</span>',
            autoplay: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false,
                        dots: true,
                        dotsClass: 'slick-dots'
                    }
                }
            ]
        }
    );
}

document.addEventListener('DOMContentLoaded', initMaierCarouselPresentationSlick);