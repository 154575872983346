function createPreHeaderOffer(content) {
    let preHeader = document.querySelector('.maier-pre-header');
    let preHeaderIcon = document.querySelector('.maier-pre-header i');
    let preHeaderDescription = document.querySelector('.maier-pre-header .maier-pre-header-content-description');
    let preHeaderSeparator = document.querySelector('.maier-pre-header .maier-pre-header-content-separator');
    let preHeaderLink = document.querySelector('.maier-pre-header .maier-pre-header-content-link');

    preHeaderIcon.remove();
    preHeaderDescription.innerHTML = content;
    preHeader.style.backgroundColor = '#CD5B1B';
    preHeaderDescription.style.color = '#FFFFFF';
    preHeaderSeparator.remove();
    preHeaderLink.remove();
}

document.addEventListener('DOMContentLoaded', function () {
    let meta = document.querySelector('meta[name="pre-header"]');
    console.log(meta);
    if (null === meta) {
        return;
    }

    createPreHeaderOffer(meta.getAttribute('content'));
})